<template>
	<div>
		<section class="auth-section">
			<header class="section-header blind">
				<h3 class="title">회원가입 동의</h3>
			</header>
			<div class="signup-agree-list">
				<p class="signup-agree-list__desc">*유의사항</p>
				<ul class="signup-agree-list__ul">
					<li>소상공인의 경우 기업/소상공인 정보섹션에 <strong>사업자 등록번호</strong>를 입력하여 진위여부를 인증 후 해당 정보를 등록하시길 바랍니다.</li>
					<li><strong>사업자 등록번호</strong>가 인증되지 않은 사용자의 경우 기업/소상공인 회원이 아닌 일반회원으로 가입되어 플랫폼의 기능을 모두 사용할 수 없습니다.</li>
					<li class="color-red">- 커뮤니티, e 러닝, 시설예약 등 기능 사용 불가</li>
				</ul>
			</div>
			<div class="auth-buttons">
				<button class="btn btn-xl btn-default" @click="clickCancel">
					<span class="text">취소</span>
				</button>
				<button class="btn btn-xl btn-primary" @click="clickNext">
					<span class="text">다음</span>
				</button>
			</div>
		</section>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { MUT_SET_RETURN_ROUTE } from '../../store/_mut_consts';

export default {
	name: 'SignupChk',
	props: {
		changeStep: Function,
	},
	data: () => ({}),
	computed: {
		...mapGetters('auth', ['checkMeInfo']),
		...mapGetters('common', ['returnRoute'])
	},
	methods: {
		clickNext() {
			this.changeStep(2);
		},
		clickCancel() {
      if (this.returnRoute !== null) {
        this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, null);
        this.$router.go(-1);
      } else {
        this.$router.push({name:'Main'});
      }
    },
	},
};
</script>
