<template>
	<div>
		<form class="form-field" onsubmit="return false;">
			<!-- s: 1. 기업/소상공인 정보 -->
      <!--
      <fieldset>
				<legend>1. 기업/소상공인 정보</legend>
				<p class="form-subdesc">※ 하단의 기업/소상공인 정보를 모두 입력하여 인증해야 플랫폼의 모든 기능을 사용할 수 있습니다. <br>(사용자 정보만 입력할 경우 계정은 생성되나 일부 기능만 사용 가능합니다.)</p>

				&lt;!&ndash; segment-field:사업자등록번호  &ndash;&gt;
				<div class="segment-field" id="field-sme">
					<div class="form-row">
						<div class="form-cellth">
							<label>법인명(기업명) </label>
						</div>
						<div class="form-celltd">
							<input v-model.trim="compNm" type="text" class="form-control" placeholder="법인명 입력" />
						</div>
					</div>
					<div class="form-row">
						<div class="form-cellth">
							<label>사업자등록번호 </label>
						</div>
						<div class="form-celltd">
							<input v-model.trim="prtlBrno" type="text" class="form-control" placeholder="사업자등록번호 입력" />
						</div>
					</div>
					<div class="form-row">
						<div class="form-cellth">
							<label>대표명 </label>
						</div>
						<div class="form-celltd">
							<input v-model.trim="ceoNm" type="text" class="form-control" placeholder="이름 입력" />
						</div>
					</div>
					<div class="form-row">
						<div class="form-cellth">
							<label>개업일자 </label>
						</div>
						<div class="form-celltd">
							<input v-model.trim="openDay" type="text" class="form-control" placeholder="입력예시) 2002년01월01일 > 20020101" />
						</div>
					</div>
				</div>
				<button class="btn btn-lg btn-primary is-fullwidth" :class="{ 'is-disabled': !isCompFilled }" @click="compCheck">
					<span class="text">{{isCompCheckMsg}}</span>
				</button>

				&lt;!&ndash; segment-controller 크리에이터 미사용 &ndash;&gt;
				&lt;!&ndash;
        <div class="segment-controller">
          <ul class="segment-list">
            <li v-model="params.userPattnCd" class="segment-item" @click="clickSegment('200')" :class="{'is-selected' : params.userPattnCd === '200'}">
              <a href="javascript:" class="segment-link">
                <span class="segment-text">소상공인</span>
              </a>
            </li>
            <li v-model="params.userPattnCd" class="segment-item" role="tab" @click="clickSegment('300')" :class="{'is-selected' : params.userPattnCd  === '300'}">
              <a href="javascript:" class="segment-link">
                <span class="segment-text">크리에이터</span>
              </a>
            </li>
          </ul>
        </div>
        &ndash;&gt;
			</fieldset>-->
			<!-- e: 1. 기업/소상공인 정보 -->

			<!-- s: 2. 사용자 정보 -->
			<fieldset>

        <!--	<legend>2. 사용자 정보</legend>-->

				<!-- segment-field:소상공인 -->
				<div class="segment-field" id="field-sme">
					<div class="form-row">
						<div class="form-cellth">
							<label>이름 <span style="color: red">*</span></label>
						</div>
						<div class="form-celltd">
							<input v-model="params.userNm" type="text" class="form-control" placeholder="이름 입력" readonly />
						</div>
					</div>

					<div class="form-row">
						<div class="form-cellth">
							<label>아이디 <span style="color: red">*</span></label>
						</div>
						<div class="form-celltd">
							<input v-model="lgnId" type="text" class="form-control" placeholder="아이디 입력" />
							<small class="small-text" style="color: red" v-if="!idInit && !isLanguageCheck">* 아이디는 5~20자의 영문 소문자, 숫자와 특수기호(_),(-)만 사용 가능합니다.</small>
						</div>
						<button class="btn btn-lg btn-primary" @click="duplicateCheck">
							<span class="text">중복확인</span>
						</button>
					</div>
					<div class="form-row">
						<div class="form-cellth">
							<label>비밀번호 <span style="color: red">*</span></label>
						</div>
						<div class="form-celltd">
							<div class="form-group">
								<input v-model="userPasswd" class="form-control" :type="isTogglePassword ? 'password' : 'text'" value="" placeholder="영문, 숫자, 특수문자 포함 9-20자리로 입력" data-actions="true" autocomplete="" />
								<button type="button" class="btn-security" @click="togglePassword">
									<i :class="isTogglePassword ? 'icon-security' : 'icon-security-off'"></i>
								</button>
							</div>
							<small class="small-text" style="color: red" v-if="!passwordInit && !isPasswordRegx && userPasswd.length > 0">* 영문, 숫자, 특수문자 포함 9-20자리로 입력해주세요.</small>
						</div>
					</div>
					<div class="form-row">
						<div class="form-cellth">
							<label>비밀번호 확인 <span style="color: red">*</span></label>
						</div>
						<div class="form-celltd">
							<input v-model="userPasswdChk" :type="isTogglePassword ? 'password' : 'text'" class="form-control" placeholder="비밀번호 확인" :data-valid="isPasswordCheck ? '' : 'invalid'" autocomplete="" />
							<small class="small-text" style="color: red" v-if="!isPasswordCheck && userPasswdChk.length > 0">* 비밀번호가 일치하지 않습니다.</small>
						</div>
					</div>

					<div class="form-row">
						<div class="form-cellth">
							<label>전화번호</label>
						</div>
						<div class="form-celltd">
							<input v-model="telNo" type="text" maxlength="13" class="form-control" placeholder="전화번호 입력" />
						</div>
					</div>

					<div class="form-row">
						<div class="form-cellth">
							<label>휴대폰 <span style="color: red">*</span></label>
						</div>
						<div class="form-celltd">
							<input v-model="mblNo" type="text" class="form-control" placeholder="휴대폰번호 입력" readonly />
						</div>
					</div>

					<div class="form-row">
						<div class="form-cellth">
							<label>이메일 <span style="color: red" v-if="params.userPattnCd === '300'">*</span></label>
						</div>
						<div class="form-celltd">
							<input v-model="userEmail" type="text" class="form-control" placeholder="이메일 입력" :data-valid="!isEmailCheck ? '' : 'check'" />
							<small class="small-text" style="color: red" v-if="!isEmailCheck && userEmail.length > 0">* 이메일 형식이 맞지 않습니다.</small>
						</div>
					</div>

					<!-- 크리에이터 미사용 -->
					<!--
        <template v-if="params.userPattnCd === '300'">
          <div class="form-row">
            <div class="form-cellth">
              <label>크리에이터 <br/>이름 <span style="color: red">*</span></label>
            </div>
            <div class="form-celltd">
              <input v-model="params.bcCtrDto.ctrNm" type="text" class="form-control" placeholder="크리에이터 이름">
            </div>
          </div>

          <div class="form-row">
            <div class="form-cellth">
              <label>크리에이터 <br/>활동명 <span style="color: red">*</span></label>
            </div>
            <div class="form-celltd">
              <input v-model="params.bcCtrDto.ctrActvtNm" type="text" class="form-control" placeholder="크리에이터 활동명">
            </div>
          </div>

          <div class="form-row">
            <div class="form-cellth">
              <label> 성별 <span style="color: red">*</span></label>
            </div>
            <div class="form-celltd">
              <select v-model="params.bcCtrDto.gndrCd" class="form-control">
                <option value="">선택</option>
                <option v-for="(option, idx) in gndrCdOptions" :value="option.cmmnCdVal" :key="idx">{{ option.cmmnCdValNm }}</option>
              </select>
            </div>
          </div>

          <div class="form-row">
            <div class="form-cellth">
              <label>유튜브 <span style="color: red">*</span></label>
            </div>
            <div class="form-celltd">
              <select v-model="params.bcCtrDto.ytbChnlCtgrTypCd" class="form-control mb-1">
                <option value="">선택</option>
                <option v-for="(option, idx) in ytbChnlCtgrTypCdOptions" :value="option.cmmnCdVal" :key="idx">{{ option.cmmnCdValNm }}</option>
              </select>
              <input v-model="params.bcCtrDto.ytbChnlUrl" type="text" class="form-control mb-1" placeholder="유튜브 url" required="">
              <input v-model="params.bcCtrDto.ytbChnlNm" type="text" class="form-control" placeholder="유튜브 채널명" required="">
            </div>
          </div>

          <div class="form-row">
            <div class="form-cellth">
              <label>우편번호</label>
            </div>
            <button v-if="isMobile" class="btn btn-primary btn-form mb-2" type="button" @click="showDaumPost"><span class="text">우편 번호 찾기</span></button>

            <div class="form-celltd" v-if="!isMobile">
              <input v-model="params.bcCtrDto.zipNo" class="form-control" type="text" placeholder="우편 번호 입력" required="">
            </div>
            <button v-if="!isMobile" class="btn btn-lg btn-primary btn-form" type="button" @click="showDaumPost"><span class="text">우편 번호 찾기</span></button>
          </div>

          <div class="form-row"  v-if="isMobile">
            <div class="form-celltd">
              <input v-model="params.bcCtrDto.zipNo" class="form-control" type="text" placeholder="우편 번호 입력">
            </div>
          </div>

          <div class="form-row">
            <div class="form-celltd">
              <input v-model="params.bcCtrDto.bscAddr" class="form-control" type="text" placeholder="주소 입력">
            </div>
          </div>

          <div class="form-row">
            <div class="form-celltd">
              <input v-model="params.bcCtrDto.dtlAddr" class="form-control" type="text" placeholder="상세 주소 입력">
            </div>
          </div>

          <div class="form-row">
            <div class="form-cellth">
              <label>비고</label>
            </div>
            <div class="form-celltd">
              <div class="form-celltd">
                <textarea v-model="params.bcCtrDto.ctrNote" class="form-control" rows="4" placeholder="비고를 입력하세요"></textarea>
              </div>
            </div>
          </div>
        </template>
        -->

					<template v-if="params.userPattnCd === '200'">
						<div class="form-row" style="padding-top: 30px">
							<div class="form-cellth">
								<label>
									온라인 시장<br />
									진출교육<br />
									(e-러닝) 수강<br />
									정보제공 동의
								</label>
							</div>
							<div class="form-celltd" style="justify-content: space-between; display: flex">
								<div>
									<input type="radio" v-model="felrgEduTknlcrsAplyYn" id="felrgEduTknlcrsAplyY" class="form-check-input gachi-cursor-pointer" value="Y" />
									<label for="felrgEduTknlcrsAplyY" class="form-check-label" style="padding-right: 20px">예</label>
									<input type="radio" v-model="felrgEduTknlcrsAplyYn" id="felrgEduTknlcrsAplyN" class="form-check-input gachi-cursor-pointer" value="N" />
									<label for="felrgEduTknlcrsAplyN" class="form-check-label">아니오</label>
								</div>
								<a href="/service/freeedudetail" class="agree-link" target="_blank"><span class="text">자세히보기</span><i class="icon-arrow"></i></a>
							</div>
						</div>
						<div class="form-row is-noth">
							<div class="form-celltd">
								<div class="text-left">
									<small class="small-text color-danger"> ※ 수강 희망하시는 분들의 이름, 가치삽시다 ID, 전화번호, 이메일 등이 교육플랫폼(GS ITM GetSmart)에 제공되며, 정보제공 동의 후 5일 이내에 포털 '온라인진출 배움터' 메뉴를 통해 무료교육을 수강하실 수 있습니다. </small>
								</div>
							</div>
						</div>
					</template>
				</div>
			</fieldset>
			<!-- e: 2. 사용자 정보 -->

			<!-- auth-buttons -->
			<div class="auth-buttons">
				<button class="button-cancel-80h btn btn-xl btn-default" @click="clickCancel">
					<span class="text">취소</span>
				</button>
				<loading-button button-text="가입" :is-loading="isLoading" :click-func="clickSave" :button-classes="['btn', 'btn-primary', 'button-blue-80h', 'btn-xl']"></loading-button>
			</div>
		</form>
	</div>
</template>
<script>
import { 
	MUT_SET_COMMON_CODE_ITEMS, 
	MUT_SHOW_ADDRESS_MODAL,
	MUT_SHOW_ALERT, 
	MUT_SHOW_CONFIRM,
	MUT_SET_RETURN_ROUTE 
} from '../../store/_mut_consts';
import { mapGetters } from 'vuex';
import { ACT_CHECK_COMP_INFO, ACT_CHECK_DUPLICATE_ID, ACT_GET_SHOP_MALL_COMMON_CODE_LIST, ACT_SIGNUP_USER } from '../../store/_act_consts';
import { getCheckItems, isSuccess, phoneNumberMask, strToHex, validate } from '../../assets/js/utils';
import { checkEmail, checkId, checkPassword } from '../../assets/js/modules/validation';
import LoadingButton from '../common/LoadingButton';

import SHA512 from 'crypto-js/sha512';
import Base64 from 'crypto-js/enc-base64';

export default {
	name: 'SignupForm',
	props: {
		callbackParams: String, //캐시프랜즈 API 부모페이지 Signup.vue callbackParams (20220808 hib)
	},
	components: {
		LoadingButton,
	},
	data: () => ({
		isLoading: false,
		isSelected: 1,
		postLoading: false,
		isDuplicateCheck: false,

		params: {
			userNm: '', // 이름 : 필수
			lgnId: '', // 로그인 아이디 : 필수
			userEmail: '', // 이메일 : 필수(크리에이터)
			userPasswd: '', // 비밀번호 : 필수
			mblNo: '', // 핸드폰 번호 : 필수
			telNo: '', // 전화번호
			userPattnCd: '200', // 소상공인 200, 크리에이터 300
      authCode: '',
			felrgEduTknlcrsAplyYn: '', //무료 e-러닝 교육 희망여부
			prtlBrno: '', //사업자등록번호
			openDay: '', //개업일자
			ceoNm: '', //대표명
			compNm: '', //기업명
			isCompCheck: false, //사업자정보 검증실행여부
			bcCtrDto: {
				ctrNm: '', // 크리에이터 이름 : 필수
				ctrActvtNm: '', // 활동명
				ytbChnlUrl: '', // 유튜브 채널 url : 필수
				ytbChnlNm: '', // 유튜브 채널명 : 필수
				ytbChnlCtgrTypCd: '', // YTB_CHNL_CTGR_TYP_CD 코드 이용

				telNo: '', // 전화번호
				mblNo: '', // 핸드폰 번호 : 필수
				ctrEmail: '', // 크리에이터 이메일 : 필수
				zipNo: '', // 우편번호
				bscAddr: '', // 주소
				dtlAddr: '', // 상세주소

				ctrNote: '', // 비고
				gndrCd: '', // code
			},
		},
		profile: {
			nickNm: '',
		},

		lgnId: '',
		userPasswd: '',
		userPasswdChk: '',
		telNo: '',
		mblNo: '',
		userEmail: '',
		felrgEduTknlcrsAplyYn: 'N',
		idInit: true,
		isLanguageCheck: false,
		isTogglePassword: true,
		passwordInit: true,
		isPasswordCheck: false,
		isPasswordRegx: false,
		isEmailCheck: false,

		prtlBrno: '', //사업자등록번호
		openDay: '', //개업일자
		ceoNm: '', //대표명
		compNm: '', //기업명
	}),
	computed: {
		...mapGetters('common', ['isMobile', 'commonCode', 'returnRoute']),
		...mapGetters('auth', ['checkMeInfo']),
		gndrCdOptions() {
			return this.commonCode.filter((x) => x.cmmnCd === 'GNDR_CD');
		},
		ytbChnlCtgrTypCdOptions() {
			return this.commonCode.filter((x) => x.cmmnCd === 'YTB_CHNL_CTGR_TYP_CD');
		},
		isCompFilled() {
			return this.compNm && this.prtlBrno && this.ceoNm && this.openDay;
		},
    isCompCheckMsg() {
      var res = '진위여부';
      if(this.params.isCompCheck){
        res = '진위여부(확인완료)'
      }
      return res;
    }
	},
	watch: {
		prtlBrno() {
			this.params.isCompCheck = false; //변경 시 진위여부 리셋
		},
		openDay() {
			this.params.isCompCheck = false; //변경 시 진위여부 리셋
		},
		ceoNm() {
			this.params.isCompCheck = false; //변경 시 진위여부 리셋
		},
		compNm() {
			this.params.isCompCheck = false; //변경 시 진위여부 리셋
		},
		telNo() {
			this.telNo = phoneNumberMask(this.telNo);
		},
		mblNo() {
			this.mblNo = phoneNumberMask(this.mblNo);
		},
		lgnId() {
			this.idInit = false;
			this.isDuplicateCheck = false;
			this.isLanguageCheck = checkId(this.lgnId);
		},
		userPasswd() {
			this.passwordInit = false;
			// 숫자 + 문자 9~20자리
			this.isPasswordRegx = checkPassword(this.userPasswd);
			if (this.isPasswordRegx) {
				this.isPasswordCheck = this.userPasswd === this.userPasswdChk;
			} else {
				this.isPasswordCheck = true;
			}
		},
		userPasswdChk() {
			this.isPasswordCheck = this.userPasswd === this.userPasswdChk;
		},
		userEmail() {
			this.isEmailCheck = checkEmail(this.userEmail);
		},
	},
	created() {
		if (this.commonCode.length === 0) this.getCommonCdList();
	},
	beforeMount() {
		this.setInit();
	},
	methods: {
		setInit() {
			this.params.userNm = this.checkMeInfo.name;
      this.params.authCode = SHA512(SHA512(`${this.checkMeInfo.name}_auth`).toString().toUpperCase()).toString().toUpperCase();
			this.mblNo = this.checkMeInfo.mblNo;
			this.params.bcCtrDto.mblNo = this.checkMeInfo.mblNo;
			this.params.bcCtrDto.gndrCd = this.checkMeInfo.gndrCd;
		},
		getCommonCdList() {
			this.$store.dispatch(`common/${ACT_GET_SHOP_MALL_COMMON_CODE_LIST}`, {}).then((res) => {
				this.$store.commit(`common/${MUT_SET_COMMON_CODE_ITEMS}`, getCheckItems(res));
			});
		},
		clickCancel() {
			this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
				title: `회원가입을 취소하시겠습니까?`,
				showCancelButton: true,
				yesfunc: () => {
					if (this.returnRoute !== null) {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, null);
						this.$router.go(-1);
					} else {
						this.$router.push({name:'Main'});
					}
				},
				nofunc: () => {
					this.isLoading = false;
				},
			});
		},
		clickSegment(segment) {
			this.params.userPattnCd = segment;
		},
		showDaumPost() {
			if (this.postLoading) return;
			this.postLoading = true;
			this.$store.commit(`common/${MUT_SHOW_ADDRESS_MODAL}`, {
				size: 'md',
				okFunc: (a) => {
					this.params.bcCtrDto.zipNo = a.zonecode;
					this.params.bcCtrDto.bscAddr = a.address;
					this.postLoading = false;
				},
				cancelFunc: () => {
					this.postLoading = false;
				},
			});
		},
		duplicateCheck() {
			if (this.lgnId.trim().length === 0) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: `아이디를 입력해주세요.`,
				});
				return;
			}

			if (!this.isLanguageCheck) {
				this.isLoading = false;
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: '아이디는 5~20자의 영문 소문자, <br/>숫자와 특수기호(_),(-)만 사용 가능합니다.',
					html: true,
				});
				return;
			}

			this.$store.dispatch(`auth/${ACT_CHECK_DUPLICATE_ID}`, this.lgnId).then((res) => {
				this.isDuplicateCheck = isSuccess(res);
				if (this.isDuplicateCheck) {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: `사용 할 수 있는 아이디입니다. `,
					});
				} else {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: `중복 된 아이디입니다.`,
					});
				}
			});
		},
		compCheck() {
			var param = {
				prtlBrno: this.prtlBrno, //사업자등록번호
				openDay: this.openDay, //개업일자
				ceoNm: this.ceoNm, //대표명
				compNm: this.compNm, //기업명
			};

			this.$store.dispatch(`auth/${ACT_CHECK_COMP_INFO}`, param).then((res) => {
				this.params.isCompCheck = isSuccess(res);
				if (this.params.isCompCheck) {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: `인증되었습니다.`,
					});
					this.params.prtlBrno = this.prtlBrno;
					this.params.openDay = this.openDay;
					this.params.ceoNm = this.ceoNm;
					this.params.compNm = this.compNm;
				} else {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: `해당하는 회사정보가 없습니다. 입력한 정보가 맞는지 확인해주세요.`,
					});
				}
			});
		},
		clickSave() {
			if (this.isLoading) return;
			this.isLoading = true;

			// params setting
			this.params.lgnId = this.lgnId;
			this.params.userPasswd = this.userPasswd;
			this.params.telNo = this.telNo.replace('-', '').replace('-', '');
			this.params.mblNo = this.mblNo.replace('-', '').replace('-', '');
			this.params.userEmail = this.userEmail;

			// 기본체크
			let result = validate(this.$store, this.params, [
				{ key: 'userNm', msg: '이름을 입력해주세요.' },
				{ key: 'lgnId', msg: '아이디를 입력해주세요.' },
				{ key: 'userPasswd', msg: '비밀번호를 입력해주세요.' },
				{ key: 'mblNo', msg: '휴대폰 번호를 입력해주세요.' },
			]);

			//소상공인이면 무료 e-러닝 교육 희망여부 받음
			if (result && this.params.userPattnCd === '200') {
				this.params.felrgEduTknlcrsAplyYn = this.felrgEduTknlcrsAplyYn;
			}

			// 소상공인 필수 : 이름, 아이디, 비밀번호, 휴대폰
			// 크리에이터 필수 : 이름, 아이디, 비밀번호, 휴대폰, 이메일, 크리에이터 이름, 크리에이터 활동명, 성별
			// 크리에이터 휴대폰, 이메일은 소상공인의 휴대폰, 이메일 값으로 데이터 전송
			if (result && this.params.userPattnCd === '300') {
				this.params.bcCtrDto.mblNo = this.mblNo.replace('-', '').replace('-', '');
				this.params.bcCtrDto.telNo = this.telNo.replace('-', '').replace('-', '');
				this.params.bcCtrDto.ctrEmail = this.userEmail;

				result = validate(this.$store, this.params.bcCtrDto, [
					{ key: 'ctrNm', msg: '크리에이터 이름을 입력해주세요.' },
					{ key: 'ctrEmail', msg: '크리에이터 이메일을 입력해주세요.' },
					{ key: 'ctrActvtNm', msg: '크리에이터 활동명을 입력해주세요.' },
					{ key: 'gndrCd', msg: '성별을 선택해주세요.' },
					{ key: 'ytbChnlUrl', msg: '유튜브 URL를 입력해주세요.' },
					{ key: 'ytbChnlNm', msg: '유튜브 채널명을 입려해주세요.' },
				]);
			}

			// validation complete
			if (result) {
				if (!this.checkClientParams()) {
					this.isLoading = false;
					return;
				}

        var title = "회원가입을 완료하시겠습니까?";

				/*
        if(!this.params.isCompCheck){
          title = "기업/소상공인 정보가 입력되지 않았습니다. 일반회원으로 가입 하시겠습니까? (일반회원의 경우 기업/소상공인 회원이 사용할 수 있는 서비스 일부를 사용할 수 없습니다.)";
        }
        */

        this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
					title: title,
					showCancelButton: true,
					yesfunc: this.signUp,
					nofunc: () => {
						this.isLoading = false;
					},
				});
			} else {
				this.isLoading = false;
			}
		},
		signUp() {
			this.params.userPasswd = strToHex(SHA512(this.params.userPasswd).toString(Base64));
			if (!this.params.isCompCheck) {
				this.params.prtlBrno = '';
				this.params.compNm = '';
				this.params.ceoNm = '';
				this.params.openDay = '';
			}
			this.$store.dispatch(`auth/${ACT_SIGNUP_USER}`, this.params).then((res) => {
				if (isSuccess(res)) {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: `회원가입이 완료되었습니다.`,
						yesfunc: () => {
							this.$router.push({ name: 'SignupComplete', params: { callbackParams: this.callbackParams, lgnId: this.lgnId } });
						},
						//yesfunc: () => {this.$router.push({name:'SignupComplete'});}
					});
				} else {
					this.isLoading = false;
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: `회원가입이 실패하였습니다.`,
					});
				}
			});
		},
		togglePassword() {
			// 비밀번호 show/hide
			this.isTogglePassword = !this.isTogglePassword;
		},
		checkClientParams() {
			let msg = '';
			let result = false;

			// 아이디 포맷 체크
			if (!this.isLanguageCheck) {
				msg = '아이디는 5~20자의 영문 소문자, <br/>숫자와 특수기호(_),(-)만 사용 가능합니다.';
			} else if (!this.isDuplicateCheck) {
				msg = '아이디 중복확인을 해주세요.';
			} else if (!this.isPasswordRegx) {
				msg = '비밀번호는 영문, 숫자, 특수문자 포함<br>8-16자리로 입력해주세요.';
			} else if (!this.isPasswordCheck) {
				msg = '비밀번호가 일치하지 않습니다.';
			} else {
				result = true;
			}

			if (!result) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: msg,
					html: true,
				});
			}
			return result;
		},
	},
};
</script>
