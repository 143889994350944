<template>
	<main class="page-container" id="sme-signup">
		<div class="auth-component" :class="{ 'is-wide': step === 2 }">
			<header class="auth-header">
				<h2 class="title">회원가입</h2>
			</header>
			<div class="auth-body">
        <!--
        <signup-cert :change-step="changeStep" v-if="step === 0" />
				<signup-chk :change-step="changeStep" v-else-if="step === 1" />
        -->
        <signup-cert :change-step="changeStep" v-if="step === 1" />
				<signup-form v-else-if="step === 2" :callbackParams="callbackParams" />
				<!-- <signup-form v-else-if="step === 2"/> -->
			</div>
		</div>
	</main>
</template>

<script>
import SignupCert from '../../../components/signup/SignupCert';
import SignupChk from '../../../components/signup/SignupChk';
import SignupForm from '../../../components/signup/SignupForm';
import { queryToValue } from '../../../assets/js/utils';

export default {
	name: 'Signup',
	components: {
		SignupCert,
		//SignupChk,
		SignupForm,
	},
	data: () => ({
		step: 1,
		callbackParams: '', //캐시프랜즈 API callbackParams 선언 (20220808 hib)
	}),
	created() {
		//캐시프랜즈 API callbackParams 데이터 저장 (20220808 hib)
		this.callbackParams = queryToValue(this.$route.query.callbackParams, false, '');
	},
	methods: {
		changeStep(step) {
			this.step = step;
		},
	},
};
</script>
