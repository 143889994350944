<template>
  <div>
    <section class="auth-section">
      <header class="section-header blind">
        <h3 class="title">회원가입 동의</h3>
      </header>
      <fieldset>
        <div class="signup-agree">
          <div class="form-check form-check-box">
            <input class="form-check-input" type="checkbox" id="chkAgreeAll" @change="checkedAll($event)" v-model="chkAgreeAll">
            <label class="form-check-label" for="chkAgreeAll">
              <span class="icon-check"></span>
              <span class="text">전체동의</span>
            </label>
          </div>
          <ul class="agree-list">
            <li class="agree-item">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="chkAgree01" value="chkAgree01" v-model="chkAgree01" @change="changeCheck">
                <label class="form-check-label" for="chkAgree01">
                  <span class="text">이용약관에 동의 (필수)</span>
                </label>
              </div>
              <router-link :to="{name: 'Policy'}" target="_blank" class="agree-link">
                <span class="text">자세히보기</span>
                <i class="icon-arrow"></i>
              </router-link>
            </li>
            <li class="agree-item">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="chkAgree02" value="chkAgree02" v-model="chkAgree02" @change="changeCheck">
                <label class="form-check-label" for="chkAgree02">
                  <span class="text">개인정보 수집 및 이용에 동의 (필수)</span>
                </label>
              </div>
              <!-- <a :href="`${marketUrl}/mn/cm/mncm010`"  target="_blank" class="agree-link">
                <span class="text">자세히보기</span>
                <i class="icon-arrow"></i>
              </a> -->
              <router-link :to="{ name:'PrivacyPolicy' }" class="agree-link">
                <span class="text">자세히보기</span>
                <i class="icon-arrow"></i>
              </router-link>
            </li>
          </ul>
        </div>
      </fieldset>
      <fieldset>
        <div class="signup-auth">
          <button class="btn btn-xl btn-outline-primary btn-verify btn-wide" @click="startCheckMe">
            <i :class="isCheckMe ? 'icon-check' : 'icon-phone'"></i>
            <span class="text">본인인증 {{ isCheckMe ? '완료' : '' }}</span>
          </button>
          <div class="form-row">
                       <!-- <div class="form-cellth">-->
            <!--              <label>인증번호</label>-->
            <!--            </div>-->
            <!--            <div class="form-celltd">-->
            <!--              <div class="form-row">-->
            <!--                <input v-model="cert" type="text" class="form-control" placeholder="인증번호 입력">-->
            <!--                <button class="btn btn-lg btn-primary" :disabled="!isCert">-->
            <!--                  <span class="text">재전송</span>-->
            <!--                </button>-->
            <!--              </div>-->
            <!--            </div> -->
          </div>
        </div>
      </fieldset>
      <div class="auth-buttons">
        <button class="btn btn-xl btn-default" @click="clickCancel">
          <span class="text">취소</span>
        </button>
        <button class="btn btn-xl btn-primary" :disabled="!isChkAll" @click="clickNext">
          <span class="text">다음</span>
        </button>
      </div>
    </section>
  </div>
</template>
<!--<style scoped>-->
<!--#sme-signup .signup-auth .btn-verify .icon-check {position:absolute;left:35px;width:30px;height:30px;background-image:url("../../../src/assets/img/common/icon_form_checkbox_active.svg")}-->
<!--</style>-->
<script>
import {
  MUT_SHOW_ALERT,
  MUT_SET_RETURN_ROUTE
} from '../../store/_mut_consts';
import {selfAuth} from '../../assets/js/selfAuth';
import {ACT_GET_USER_FIND_ID_LIST} from '../../store/_act_consts';
import {getItem, lengthCheck} from '../../assets/js/utils';
import {mapGetters} from 'vuex';

export default {
  name: 'SignupCert',
  props: {
    changeStep: Function
  },
  data: () => ({
    chkAgreeAll: false,
    chkAgree01: false,
    chkAgree02: false,
    isCert: false,
    cert: '',
    isCheckMe: false,
    marketUrl: `${process.env.VUE_APP_GACHI_MALL_HOST}`
  }),
  computed: {
    ...mapGetters('auth', ['checkMeInfo']),
    ...mapGetters('common', ['returnRoute']),
    isChkAll(){
      return (this.chkAgree01 && this.chkAgree02);
    },
  },
  mounted() {
    // this.isCheckMe = true;
    // this.checkMeInfo.name = '백승전';
    // this.checkMeInfo.mblNo = '010-2408-1003';
  },
  methods: {
    changeCheck(){
      this.chkAgreeAll = (this.chkAgree01 && this.chkAgree02);
    },
    checkedAll(e){
      if(e.target.checked){
        this.chkAgree01 = true;
        this.chkAgree02 = true;
      } else {
        this.chkAgree01 = false;
        this.chkAgree02 = false;
      }
    },
    startCheckMe() {
      selfAuth(this.$store, this);
    },
    clickNext(){
      if( !this.isCheckMe ) {
        this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '본인 인증을 해 주셔야 합니다.'});
      } else {
        this.changeStep(2);
      }
      // this.$router.push({name:'Signup'});
    },
    clickCancel() {
      if (this.returnRoute !== null) {
        this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, null);
        this.$router.go(-1);
      } else {
        this.$router.push({name:'Main'});
      }
    },
    checkMeInfoSuccess() {
      this.$store.dispatch(`auth/${ACT_GET_USER_FIND_ID_LIST}`, {
        userNm: this.checkMeInfo.name,
        mblNo: this.checkMeInfo.mblNo
      }).then(res => {
        if(lengthCheck(res)){
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '이미 가입된 아이디가 있습니다.',});
          this.$router.push({name: 'Login'});
        }else{
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '본인인증이 완료되었습니다.'});
        }
      }).catch(e => {
        console.error(e);
      });
    }
  }
};
</script>